<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4 mb-6">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="
                  require('@/assets/img/interactive-clinical-case/step8_icon1.svg')
                "
              />
              Дискуссия с экспертом
            </div>
            <div class="text-1 mb-6">
              <b>Цель назначения антикоагулянтов</b> – снижение риска
              тромбоэмболических осложнений (ишемического инсульта, транзиторных
              ишемических атак и системных тромбоэмболий)<sup>1</sup>.
            </div>
            <div class="icc__block-video mx-auto mb-6">
              <video
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/7.mp4"
                :poster="
                  require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                "
                controls
              />
            </div>
            <div>
              <blockquote class="px-6 py-4 mb-4">
                <div class="text-1 mb-2">
                  Фибрилляция предсердий (ФП) увеличивает риск развития инсульта
                  в 5 раз, однако риск неоднороден и зависит от факторов,
                  которые суммированы в шкале <sup>9</sup>.
                </div>
              </blockquote>
              <blockquote class="px-6 py-4 mb-4">
                <div class="text-1 mb-2">
                  Всем пациентам с ФП, не связанной с поражением клапанов
                  сердца, рекомендовано использовать шкалу <b></b> для оценки
                  риска тромбоэмболических осложнений<sup>1</sup>.
                </div>
              </blockquote>
              <div class="text-3 mb-8">
                Примечание: <b>C</b> – хроническая сердечная недостаточность (1
                балл); <b>H</b>  – гипертензия или прием гипотензивной терапии
                (1 балл); <b>A</b> – возраст 75 лет и старше терапии (2 балла);
                <b>D</b> – сахарный диабет (1 балл); <b>S</b> – инсульт или
                тромбоэмболия в анамнезе (2 балла); <b>V</b> – сосудистые
                заболевания (инфаркт в анамнезе, периферический атеросклероз) (1
                балл); <b>A</b> – возраст 65-74 года (1 балл); <b>Sc</b> –
                женский пол (1 балл).
              </div>
              <div class="d-xl-flex mb-8 mb-md-4">
                <blockquote class="px-6 py-4 mr-3 mr-md-0 mb-md-2">
                  <div class="text-1 mb-2">
                    <b
                      >Постоянный прием пероральных антикоагулянтов (ПОАК)
                      рекомендован<sup>1</sup>:</b
                    >
                  </div>
                  <div class="text-1">
                    <ul>
                      <li>
                        мужчинам с баллом CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc&nbsp;≥2
                      </li>
                      <li>
                        женщинам с баллом CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc&nbsp;≥3
                      </li>
                    </ul>
                  </div>
                </blockquote>
                <blockquote class="px-6 py-4 ml-3 ml-md-0">
                  <div class="text-1 mb-2">
                    <b
                      >Принимая во внимание индивидуальные особенности и
                      предпочтения пациента, рекомендовано назначение
                      ПОАК<sup>1</sup>:</b
                    >
                  </div>
                  <div class="text-1">
                    <ul>
                      <li>
                        мужчинам с баллом CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc&nbsp;=&nbsp;1
                      </li>
                      <li>
                        женщинам с баллом CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc&nbsp;=&nbsp;2
                      </li>
                    </ul>
                  </div>
                </blockquote>
              </div>
              <div class="text-1 mb-2">
                После выполнения любой кардиоверсии рекомендовано продолжить
                антикоагулянтную терапию в течение как минимум 4-х недель. Если
                исходно назначались гепарин и его производные, рекомендовано
                перевести пациента на пероральные антикоагулянты<sup>1</sup>.
              </div>
              <div class="text-1 mb-4">
                По истечении 4 недель после кардиоверсии рекомендовано принять
                решение о необходимости постоянной антикоагулянтной терапии,
                основываясь на риске ТЭО (шкала
                CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc). У пациентов с высоким
                риском ТЭО (для мужчин 2 и более баллов по шкале
                CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc и для женщин 3 и более баллов
                по шкале CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc), а также у
                пациентов, имевших когда-либо внутрисердечный тромб, терапию
                пероральными антикоагулянтами рекомендовано продолжать
                неопределенно долго — даже в случае сохранения синусового ритма
                после кардиоверсии<sup>1</sup>.
              </div>
              <blockquote class="px-6 py-4 mb-4">
                <div class="text-1 mb-2">
                  <b
                    >Всем пациентам перед назначением антитромботической терапии
                    рекомендовано</b
                  >
                  оценивать риск кровотечения (шкала HAS-BLED)<sup>1</sup>.
                </div>
              </blockquote>
              <div class="text-3 mb-4">
                Примечание: <b>H</b> – неконтролируемая артериальная гипертензия
                (САД>160 мм рт.ст); <b>A</b> – нарушение функции почек и/или
                печени; <b>S</b> – инсульт в анамнезе; <b>B</b> – кровотечение в
                анамнезе/анемия/тяжелая тромбоцитопения; <b>L</b> – лабильное
                МНО (ВТД &lt;60% у пациентов, получающих АВК); <b>E</b> –
                пожилые (старше 65 лет); <b>D</b> – прием
                НПВП/антиагрегантов/чрезмерное употребление алкоголя.
                <br />
                По 1 баллу за каждый признак.
                <br />
                HAS-BLED ≥3 – высокий риск кровотечений<sup>1</sup>.
              </div>
              <div class="d-flex mb-8">
                <div class="mr-3">
                  <img :src="require('@/assets/img/interactive-clinical-case/pink_warning.svg')" />
                </div>
                <div class="text-3 my-auto">
                  <i><b>Тем не менее расчетный высокий риск кровотечений не должен являться единственным ограничением к назначению антикоагулянтов<sup>1</sup>.</b></i>
                </div>
              </div>
              <div class="title-3 mb-2" style="color: #830051">
                Абсолютные противопоказания к терапии оральными антикоагулянтами:
              </div>
              <div class="text-1 mb-8">
                <ul>
                  <li>активное серьезное кровотечение;</li>
                  <li>ассоциированные сопутствующие состояния (тяжелая тромбоцитопения &lt;50/мл, тяжелая анемия);</li>
                  <li>недавнее большое кровотечение (н-р, внутричерепное кровотечение)<sup>9</sup>.</li>
                </ul>
              </div>
              <blockquote class="px-6 py-4 mb-8">
                <div class="text-1 mb-2">
                  <b
                    >Всем пациентам перед назначением антитромботической терапии рекомендовано<sup>1</sup>:</b
                  >
                  <ul>
                    <li>выявлять модифицируемые и не модифицируемые факторы риска кровотечения;</li>
                    <li>скорректировать модифицируемые факторы риска;</li>
                    <li>при наличии не модифицируемых факторов выбрать наиболее подходящий антикоагулянт в соответствии с клиническими характеристиками<sup>1</sup>.</li>
                  </ul>
                </div>
              </blockquote>
              <div class="icc__grid mb-8">
                <div>
                  <div class="title-3 mb-2" style="color: #830051">
                    <b>Модифицируемые факторы риска:</b>
                  </div>
                  <div class="text-1">
                    <ul>
                      <li>Артериальная гипертония (особенно если САД>160 мм рт.ст.);</li>
                      <li>Лабильное МНО;</li>
                      <li>Сопутствующий прием НПВП, антиагрегантов;</li>
                      <li>Злоупотребление алкоголем.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="title-3 mb-2" style="color: #830051">
                    <b>Немодифицируемые факторы риска:</b>
                  </div>
                  <div class="text-1">
                    <ul>
                      <li>Возраст старше 65 лет;</li>
                      <li>Большое кровотечение в анамнезе;</li>
                      <li>Инсульт в анамнезе;</li>
                      <li>Почечная патология, требующая диализа или трансплантации;</li>
                      <li>Цирроз печени;</li>
                      <li>Злокачественные новообразования;</li>
                      <li>Генетические факторы.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="title-3 mb-2" style="color: #830051">
                    <b>Частично модифицируемые факторы риска:</b>
                  </div>
                  <div class="text-1">
                    <ul>
                      <li>Анемия;</li>
                      <li>Нарушение функции почек;</li>
                      <li>Нарушение функции печени;</li>
                      <li>Снижение количества тромбоцитов или нарушение их функции.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="text-1 mb-2">
               Пациентам с неклапанной ФП, не получавшим ранее антикоагулянтную терапию, в качестве препаратов первой линии рекомендовано назначение прямых оральных антикоагулянтов (ПОАК)<sup>1</sup>.
              </div>
              <div class="text-1 mb-2">
                Пациентам с митральным стенозом умеренной или тяжелой степени, либо с механическим искусственным клапаном сердца с целью профилактики ТЭО рекомендованы только антагонисты витамина К (АВК)<sup>1</sup>.
              </div>
              <div class="text-1 mb-6">
                В случае назначения варфарина пациентам с митральным стенозом умеренной или тяжелой степени терапевтический диапазон МНО составляет 2,0–3,0<sup>1</sup>.
              </div>
              <div class="text-1 mb-6">
                Ацетилсалициловая кислота, клопидогрел и их комбинация не рекомендованы для профилактики инсульта и системных эмболий у пациентов с фибрилляцией предсердий<sup>1</sup>.
              </div>
              <div class="text-1 mb-4">
                Рутинное сочетание антикоагулянтов c ингибиторами агрегации тромбоцитов повышает риск кровотечений, поэтому не рекомендовано пациентам при отсутствии дополнительных показаний<sup>1</sup>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="14"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";

export default {
  name: "ICCStep19",
  components: {
    Steps,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>